import React, { useEffect, useState } from "react";
import styled from "styled-components";

const pixQR = 'https://firebasestorage.googleapis.com/v0/b/wldata.appspot.com/o/qrcode-pix.png?alt=media&token=29c62b35-3ac7-402f-bb14-01f0dc86bb2c';

export default function ModalGerarPagamento({ setShowModal, payModalMethod }) {


    const handleClose = () => {
        setShowModal(false);
    }

    console.log(payModalMethod)

    return (
        <ModalContainer>
            <ModalPagamentoOption>
                {payModalMethod === 'PIX' ? (
                    <>
                        <h4>ESCANEIE O QR CODE ABAIXO</h4>
                        <PixContent>
                            <img src={pixQR} alt="QR Code PIX" />
                        </PixContent>
                    </>
                ) : (
                    <AccountInfo>
                        <h4>CONTA PARA DEPÓSITOS</h4>

                        <div>
                            <p><span>Banco:</span> 0192</p>
                            <p><span>Agência:</span> 22348 - 5</p>
                            <p><span>Nome:</span> NOME EMPRESA</p>
                        </div>

                    </AccountInfo>
                )}
                <button onClick={handleClose}>X</button>

            </ModalPagamentoOption>
        </ModalContainer>
    )
}

const ModalContainer = styled.div`
    width: 100%;
    height: 100%;
    z-index: 2000;

    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: top;
    box-sizing: border-box;
    padding-top: 100px;
    background-color: rgba(0,0,0,0.6);
`;

const ModalPagamentoOption = styled.div`

    @media (max-width: 1000px){
        width: 350px;
    
    }

    width: 600px;

    padding: 0 10px;
    box-sizing: border-box;
    height: max-content;
    background-color: white;
    border-radius: 8px;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.7);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;

    button{
        position: absolute;
        top: 10px;
        right: 20px;
        background-color: transparent;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        border: 0px;
        font-size: 22px;
    }

    h4{
        margin: 0;
        margin-top: 50px;
        color: rgba(0,0,0,0.6);
    }
`;

const PixContent = styled.div`
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    img{
        width: 80%;
    }
`;

const AccountInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-sizing: border-box;

    div{
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 10px 40px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        border-radius: 8px;
        border: 2px solid black;
        box-shadow: 2px 2px 3px rgba(0,0,0,0.6);
        background-color: rgba(222, 215, 219, 0.8);

        align-items: center;

        p{
            margin: 0;
            font-size: 16px;
            
        }

        span{
            font-weight: 600;

        }
    }
`;