import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './styles/Nav.css';
import React, { useState } from 'react';
import assets from '../assets/assets';
import { Link, useLocation } from 'react-router-dom';

function NavComponent({ colorMode }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();

  // Verifica se location.pathname está definido e não é vazio
  let activeNav = 'HOME';
  if (location.pathname && location.pathname !== '/') {
    activeNav = location.pathname.split('/').filter(Boolean).pop().toUpperCase();
  }

  const navStyle = colorMode ? 'NavbarNavBLACKMODE' : 'NavbarNav';
  const navBrandStyle = colorMode ? 'mx-auto brand-hoverBLACKMODE' : 'mx-auto brand-hover';
  const navItemStyle = colorMode ? 'BLACKMODE' : '';

  return (
    <Navbar expand="" className={navStyle} expanded={showDropdown} onToggle={() => setShowDropdown(!showDropdown)}>
      <Container>
        <Navbar.Brand
          onClick={() => setShowDropdown(!showDropdown)}
          className={navBrandStyle}>
          {activeNav}
          <img alt='drop icon'
            className='icon-drop'
            src={colorMode ? assets.dropdownNavBLACKMODE : assets.dropdownNav} />
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav" className={showDropdown ? 'show' : ''}>
          <Nav className="nav-items">
            <Nav.Link className={navItemStyle} onClick={() => setShowDropdown(false)} as={Link} to="/home">Home</Nav.Link>
            <Nav.Link className={navItemStyle} onClick={() => setShowDropdown(false)} as={Link} to="/news">News</Nav.Link>
            <Nav.Link className={navItemStyle} onClick={() => setShowDropdown(false)} as={Link} to="/token">Token</Nav.Link>
            <Nav.Link className={navItemStyle} onClick={() => setShowDropdown(false)} as={Link} to="/saques">Saques</Nav.Link>
            <Nav.Link className={navItemStyle} onClick={() => setShowDropdown(false)} as={Link} to="/extrato">Extrato</Nav.Link>
            <Nav.Link className={navItemStyle} onClick={() => setShowDropdown(false)} as={Link} to="/downloads">Downloads</Nav.Link>
            <Nav.Link className={navItemStyle} onClick={() => setShowDropdown(false)} as={Link} to="/validacao">Validação da Conta</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavComponent;

