import TwoCircles from "./CircularProgress";
import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import TradeGrapth from "./TradeGrapth";
import Table from "./Table";
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc } from '@firebase/firestore';
import { firebaseConfig } from '../DATABASE/firebaseConfig';
import TheatherGrath from "./TheatherGrath";
import ReloadButton from "./ReloadButton";
import Loading from "./Loader";
import Tooltip from './Tooltip'

const formatNumber = (number) => {
    return number.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
};

const getCurrentDateInBrazilianFormat = () => {
    const today = new Date();
    return today.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
};

const parseDateBrazilianFormat = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(year, month - 1, day); // mês - 1 porque o JavaScript conta os meses a partir de 0
};

export default function Dashboard({ setUserDataApp }) {
    const money_icon = 'https://firebasestorage.googleapis.com/v0/b/white-lable-528b0.appspot.com/o/assets%2Fmoney-svgrepo-com%20(1).png?alt=media&token=d02dc9ff-5c35-4a0a-8cdf-af347ea1f84f';


    const [userData, setUserData] = useState(null);
    const [valorInvestido, setValorInvestido] = useState(0);
    const [tokensObtidos, setTokensObtidos] = useState(0)
    const [saldoTotal, setSaldoTotal] = useState(0);
    const [saldoIndicacao, setSaldoIndicacao] = useState(0);
    const [lucroTotal, setLucroTotal] = useState(0);
    const [saldoContratosVencidos, setSaldoContratosVencidos] = useState(0);
    const [load, setLoad] = useState(false);
    const [saquesFeitos, setSaquesFeitos] = useState(0);


    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);



    const fetchData = async () => {
        setLoad(true);
        try {
            const docRef = doc(db, 'USERS', localStorage.getItem('cpfCLIENTE'));
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setUserData(docSnap.data());
                setUserDataApp(docSnap.data());
                setSaquesFeitos(docSnap.data().VALORSACADO)
            } else {
                console.error("No such document!");
            }
            setLoad(false);

        } catch (error) {
            setLoad(false);
            console.error('Erro ao obter os dados do usuário:', error);
        }
    };


    useEffect(() => {
        fetchData();
    }, [db]);


    useEffect(() => {
        if (userData && userData.CONTRATOS) {

            const today = new Date();
            let totalInvestido = 0;
            let totalAtualComLucro = 0;

            let tokens_obtidos = 0;
            let saldo_total = 0;
            let lucro_total = 0;
            let saldo_contratos_vencidos = 0;
            let percentual_lucro_sobre_investimento = 0;


            setSaldoIndicacao(userData.INDICATIONBUDGET)

            userData.CONTRATOS.forEach(contr => {
                if (contr.STATUS) {
                    const allowSellDate = parseDateBrazilianFormat(contr.ALLOWSELL);

                    if (allowSellDate.toISOString() >= today.toISOString()) {
                        tokens_obtidos += contr.COINS;
                    } else {
                        saldo_contratos_vencidos += contr.TOTALSPENT;
                    }
                    totalInvestido += contr.TOTALSPENT;

                    saldo_total += (contr.TOTALSPENT + (contr.TOTALSPENT * (contr.LUCRO_OBTIDO / 100)))
                    lucro_total += (parseFloat(contr.TOTALSPENT * (contr.LUCRO_OBTIDO / 100)))
                }
                setTokensObtidos(tokens_obtidos);
                setSaldoTotal(saldo_total);
                setLucroTotal(lucro_total)
                setSaldoContratosVencidos(saldo_contratos_vencidos);
            });

            setValorInvestido(totalInvestido);

        }
    }, [userData]);

    const saldo_indicacao = userData ? parseFloat(userData.INDICATIONBUDGET) : 0;

    const handleReload = () => {
        fetchData();
    }


    return (
        <DashboardContainer>
            <Loading load={load} />
            <ContainerTitle>
                <p>DASHBOARD</p>

                {/* <span>1 COIN = ${valorToken}</span> */}
            </ContainerTitle>

            <ReloadButton handleReload={handleReload} />

            <ContainerContent>
                <FirstRow>
                    <TokensQtt>
                        <DivFixedRowHeader>
                            TOKENS VALORIZANDO
                        </DivFixedRowHeader>
                        <RowBottomContent>
                            <MoneyIcon>
                                <img alt="money icon" src={money_icon} />
                            </MoneyIcon>
                            <Tooltip text='TOKENS DE CONTRATOS ATIVOS'>
                                <TokenQttNumber>
                                    {userData && formatNumber(tokensObtidos)}
                                </TokenQttNumber>
                            </Tooltip>

                        </RowBottomContent>
                    </TokensQtt>


                    <BudgetAll>
                        <DivFixedRowHeader>
                            SALDO TOTAL
                        </DivFixedRowHeader>
                        <BudgetAllContent>
                            <Tooltip text='SALDO COM LUCRO + SALDO DE INDICAÇÃO'>
                                <TokenAllValue>
                                    $ {userData && (formatNumber(saldoTotal + saldoIndicacao - saquesFeitos))}
                                </TokenAllValue>
                            </Tooltip>

                        </BudgetAllContent>

                        <ProgressBar>
                            <ProgressFill percentage={100} />
                        </ProgressBar>
                        <PercentageCount>{100}%</PercentageCount>

                    </BudgetAll>

                </FirstRow>

                <SecondRow>

                    <SaldoRecompra>
                        <Tooltip text='LUCRO + SALDO CONTRATOS FINALIZADOS'>
                            Saldo de Recompra <span>$ {userData && (((lucroTotal + saldoContratosVencidos - saquesFeitos) >= 0) ? formatNumber(lucroTotal + saldoContratosVencidos - saquesFeitos) : formatNumber(lucroTotal + saldoContratosVencidos))}</span>
                        </Tooltip>

                    </SaldoRecompra>

                    <SaldoIndicacao>
                        <Tooltip text='SALDO DE INDICAÇÃO'>
                            Saldo de Indicação <span>$ {userData && formatNumber(saldoIndicacao)}</span>
                        </Tooltip>
                    </SaldoIndicacao>
                </SecondRow>

                <GrapthAreaContent>
                    <CircularProgressContent>
                        {/* Informar o Valor investido, e informar o valor investido+lucro */}
                        <TwoCircles totalSpent={valorInvestido} totalValue={valorInvestido + lucroTotal} />

                        <TwoCilclesExplanation>
                            <div>
                                <Circle color="#1940FF"></Circle> <span>Valor de Compra</span>
                            </div>

                            <div>
                                <Circle color="#22CC00"></Circle> <span>Valor dos Ganhos</span>
                            </div>
                        </TwoCilclesExplanation>

                        <p>CONTRATOS</p>
                    </CircularProgressContent>

                    <TradeGrapthContainer>
                        {/* <TradeGrapth /> */}
                        <TheatherGrath />
                    </TradeGrapthContainer>
                </GrapthAreaContent>

                <DashboardTable>
                    <Table userData={userData} />
                </DashboardTable>

            </ContainerContent>
        </DashboardContainer>
    )
}


const DashboardContainer = styled.div`
    width: 100%;
    height: max-content;
    background-color: aliceblue;
    box-sizing: border-box;
    padding: 50px 30px 200px 30px;

    display: flex;
    flex-direction: column;
    position: relative;

    @media (max-width: 800px){
        flex-direction: column;
        justify-content: center;
        padding: 60px 10px 100px 10px;

    }
`;

const ContainerTitle = styled.div`
    width: 100%;
    font-size: 28px;
    font-weight: 600;
    color: rgba(0,0,0,0.7);
    transition: .5s;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span{
        font-size: 28px;
    }

    p{
        margin: 0;
    }

    &:hover{
        padding: 0px 0px 0px 10px;
        color: rgba(232,33,1,0.7);
    }

    @media (max-width: 800px){
        flex-direction: column;

        span{
            font-size: 16px;
        }
    }
`;

const ContainerContent = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;

    @media (max-width: 800px){
        flex-direction: column;
        gap: 20px;
    }
`;

const FirstRow = styled.div`
    width: 100%;    
    height: max-content;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    gap: 20px;

    @media (max-width: 800px){
        flex-direction: column;
    }
`;

const TokensQtt = styled.div`
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    height: 130px;
    border-radius: 20px;
    box-shadow: 2px 2px 3px rgba(0,0,0,0.6);
    position: relative;
`;

const BudgetAll = styled.div`
    position: relative; /* Adiciona posicionamento relativo */
    width: 100%;
    background-color: white;
    height: 130px;
    border-radius: 20px;
    box-shadow: 2px 2px 3px rgba(0,0,0,0.6);
    overflow: hidden; 

`;

const DivFixedRowHeader = styled.div`
    width: 100%;
    display:flex;
    font-size: 18px;
    padding: 10px 0px 0px 20px;
    font-weight: 600;

    color: rgba(0,0,0,0.8);
    box-sizing: border-box;
    transition: .3s;
`;

const RowBottomContent = styled.div`
    width: 100%;
    display:flex;
    height: 100%;
    position: absolute;
    top:0;
    left: 0;
    align-items: center;
    padding: 80px 40px;
    box-sizing: border-box;
    justify-content: space-between;

    @media (max-width: 800px){
        padding: 80px 20px;
        justify-content: space-between;
    }
`;

const MoneyIcon = styled.div`
    width: 60px;
    height: 60px;
    overflow: hidden;

    display: flex;
    justify-content: center;


    img{
        width: 100%;
        height: 100%;
        opacity: 0.7;
    }
`;

const TokenQttNumber = styled.div`
    color: black;   
    font-weight: 600;
    font-size: 58px;
    color: #4D88FF;
    // text-shadow: 1px 1px 1px #0022CC;

    @media (max-width: 800px){
        font-size: 42px;
    }

`;

const BudgetAllContent = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing:border-box;
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 40px;


`;

const TokenAllValue = styled.div`
    font-size: 46px;
    color: #00CC22;
    // text-shadow: 1px 1px 1px #0D4D00;
    font-weight: 600;

    @media (max-width: 800px){
        font-size: 32px;
    }
`;

const ProgressBar = styled.div`
    position: absolute;
    bottom: 10px;
    left: 20px; /* Distância do lado esquerdo */
    right: 20px; /* Distância do lado direito */
    height: 10px;
    background-color: #ddd; /* Define a cor de fundo da barra de progresso */
    overflow: hidden; /* Esconde o conteúdo que ultrapassa os limites da barra de progresso */
`;

const ProgressFill = styled.div`
    width: ${props => props.percentage}%; /* Largura dinâmica baseada na porcentagem */
    height: 100%;
    background-color: #4CAF50; /* Cor da parte preenchida da barra de progresso */
    transition: width 4s ease; /* Define a transição da largura com duração de 4 segundos */
`;

const PercentageCount = styled.div`
    position: absolute;
    bottom: 30px;
    left: 50px;
    color: white;
    cursor: pointer;
    width: 60px;
    text-align: center;
    border-radius: 20px;
    font-weight: 600;
    background-color: green;

    @media (max-width: 800px){
        width: 40px;
        font-size: 12px;
    }
`;

const SecondRow = styled.div`
    width: 100%;    
    height: max-content;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    gap: 20px;

    @media (max-width: 800px){
        flex-direction: column;
    }
`;

const SaldoRecompra = styled.div`
    box-sizing: border-box;
    width: 100%;
    background-color: #00BFE6;
    height: 110px;
    border-radius: 20px;
    box-shadow: 2px 2px 3px rgba(0,0,0,0.6);
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
    transition: .3s;
    font-size: 22px;
    font-weight: 600;
    color: rgba(255,255,255,0.9);
    span{
        margin-left: 10px;
    }

    @media (max-width: 800px){
        flex-direction: column;
    }
`;

const SaldoIndicacao = styled.div`
    box-sizing: border-box;
    width: 100%;
    background-color: #26E600;
    height: 110px;  
    border-radius: 20px;
    box-shadow: 2px 2px 3px rgba(0,0,0,0.6);
    position: relative;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.6);

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 22px;
    font-weight: 600;
    color: rgba(255,255,255,0.9);
    span{
        margin-left: 10px;
    }

    @media (max-width: 800px){
        flex-direction: column;
    }
`;

const GrapthAreaContent = styled.div`
    width: 100%;    
    height: max-content;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    gap: 20px;

    @media (max-width: 800px){
        flex-direction: column;
    }
`;

const CircularProgressContent = styled.div`
    width: 100%;
    height: 300px;
    background-color: white;
    border-radius: 20px;
    border: 3px solid rgba(0,0,0,0.2);
    box-shadow: 2px 2px 3px rgba(0,0,0,0.6);
    box-sizing: border-box;
    display: flex;
    position: relative;

    p{
        margin: 0;
        padding: 0;

        width: 100%;
        height: 100%;
        position: absolute;
        font-weight: 600;
        padding-top: 20px;
        padding-left: 20px;
        color: rgba(0,0,0,0.7);
    }
`;


const TwoCilclesExplanation = styled.div`
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;

    align-items: end;
    gap: 20px;

    div{
        padding-bottom: 10px;
        display: flex;
        gap: 5px;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
    }
`;

const Circle = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${props => props.color || 'black'};
`;

const TradeGrapthContainer = styled.div`
    width: 150%;
    height: 300px;
    background-color: white;
    border-radius: 20px;
    border: 3px solid rgba(0,0,0,0.2);
    box-shadow: 2px 2px 3px rgba(0,0,0,0.6);
    overflow: hidden;

    @media (max-width: 800px){
        width: 100%;
        border: 0px solid rgba(0,0,0,0.0);
    }
`;

const DashboardTable = styled.div`
    width: 100%;
    margin: 20px 0; 
    border-radius: 20px;
    overflox: hidden;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    // border: 3px solid rgba(0,0,0,0.8);
    box-shadow: 3px 2px 3px rgba(0,0,0,0.6);
`;

