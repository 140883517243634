import React, { useState } from "react";
import styled from "styled-components";
import { HomeDescription, TokenDescription, SaquesDescription, NewsDescription, DownloadsDescription, PerfilDescription, ValidacaoDescription, AjudaDescription } from "./descriptions"; 

const tabContent = [
    { nome: 'HOME', descricao: <HomeDescription /> },
    { nome: 'TOKEN', descricao: <TokenDescription /> },
    { nome: 'SAQUES', descricao: <SaquesDescription /> },
    { nome: 'NEWS', descricao: <NewsDescription /> },
    { nome: 'DOWNLOADS', descricao: <DownloadsDescription /> },
    { nome: 'PERFIL', descricao: <PerfilDescription /> },
    { nome: 'VALIDAÇÃO', descricao: <ValidacaoDescription /> },
    { nome: 'AJUDA', descricao: <AjudaDescription /> },
];

export default function Funcionamento() {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <Container>
            <h1>FUNCIONAMENTO DO SISTEMA</h1>
            <Tabs>
                {tabContent.map((tab, index) => (
                    <Tab
                        key={index}
                        active={activeTab === index}
                        onClick={() => setActiveTab(index)}
                    >
                        {tab.nome}
                    </Tab>
                ))}
            </Tabs>
            <Content>{tabContent[activeTab].descricao}</Content>
        </Container>
    );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 60px 20px 20px 20px;
  text-align: center;
`;

const Tabs = styled.div`
  display: flex;
  max-width: 100%;
  overflow-x: scroll;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-wrap: no-wrap;
  gap: 20px;
`;

const Tab = styled.button`
  background: ${(props) => (props.active ? "#007BFF" : "#f1f1f1")};
  color: ${(props) => (props.active ? "#fff" : "#000")};
  border: 1px solid #ccc;
  padding: 10px 20px;
  width: 200px;
  cursor: pointer;
  margin: 0 5px;
  border-radius: 5px;
  &:hover {
    background: #007BFF;
    color: #fff;
  }
`;

const Content = styled.div`
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  max-width: 600px;
  text-align: center;
`;
