import React, { useState, useEffect } from "react";
import styled from "styled-components";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import ReloadButton from './ReloadButton'

const firebaseConfig = {
    apiKey: 'AIzaSyCnwSOjrqasUNSCp6UrK2moHd1OtLUMj28',
    authDomain: 'wldata.firebaseapp.com',
    projectId: 'wldata',
    storageBucket: 'wldata.appspot.com',
    messagingSenderId: '86184173654',
    appId: '1:86184173654:web:9463c36b71d142b684dbf7'
};

firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();

export default function News() {
    const [newsList, setNewsList] = useState([]);

    const fetchNews = async () => {
        try {
            const snapshot = await firestore.collection('NEWS').get();
            const newsData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            // Sort newsData by date in descending order
            newsData.sort((a, b) => {
                return convertDate(b.data) - convertDate(a.data);
            });
            setNewsList(newsData);
        } catch (error) {
            console.error('Erro ao buscar notícias:', error);
        }
    };


    useEffect(() => {
        fetchNews();
    }, []);

    const convertDate = (dateString) => {
        const [day, month, year] = dateString.split('/').map(Number);
        return new Date(year, month - 1, day);
    };

    const handleReload = () => {
        fetchNews();
    }

    return (
        <NewsContainer>
            <NewsBoxes>
                <ReloadButton handleReload={handleReload} />
                {newsList.map(news => (
                    <NewsCard key={news.id}>
                        <NewsImageBox>
                            <NewsImage src={news.imageUrl} alt="News Image" />
                        </NewsImageBox>
                        <NewsTitle>{news.title}</NewsTitle>
                        <NewsBody>
                            <ExpandableText text={news.body} />
                        </NewsBody>
                        <NewsData>{news.data}</NewsData>
                    </NewsCard>
                ))}
            </NewsBoxes>
        </NewsContainer>
    );
}

const ExpandableText = ({ text }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 800);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const getTextToShow = () => {
        if (isExpanded || !isMobile) {
            return text;
        }

        return text.length > 100 ? `${text.substring(0, 100)}...` : text;
    };

    return (
        <div>
            <Text>{getTextToShow()}</Text>
            {isMobile && (
                <ToggleExpandButton onClick={toggleExpand}>
                    {isExpanded ? "Ver Menos" : "Ver Mais"}
                </ToggleExpandButton>
            )}
        </div>
    );
};

const NewsContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: aliceblue;
    box-sizing: border-box;
    padding: 50px 30px 200px 40px;
    display: flex;
    flex-direction: column;
    position: relative;

    @media (max-width: 800px){
        flex-direction: column;
        justify-content: center;
        padding: 20px 10px 100px 10px;
    }
`;

const NewsBoxes = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const NewsCard = styled.div`
    width: 80%;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;

    &:hover {
        background-color: white;
    }

    @media (max-width: 800px){
        width: 90%;
    }
`;

const NewsImageBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
`;

const NewsImage = styled.img`
    width: 60%;
    border-radius: 12px;
    height: auto;
    object-fit: cover;

    @media (max-width: 800px){
        width: 90%;
    }
`;

const NewsTitle = styled.h2`
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin: 20px 10px 10px 10px;
    text-align: center;
    color: rgba(0,0,0,0.7);

    @media (max-width: 800px){
        text-align: justified;
        font-size: 20px;
        width: 90%;
    }
`;

const NewsBody = styled.div`
    font-size: 18px;
    color: #666;
    margin: 10px;
    padding: 10px 20px;
    box-sizing: border-box;
    text-align: justify;

    @media (max-width: 800px){
        font-size: 16px;
    }
`;

const Text = styled.p`
    font-size: 18px;
    color: #666;

    @media (max-width: 800px){
        font-size: 16px;
    }
`;

const ToggleExpandButton = styled.button`
    background-color: transparent;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 16px;
    padding: 0;
    margin-top: 5px;

    &:hover {
        text-decoration: underline;
    }
`;

const NewsData = styled.div`
    width: 100%;
    padding-bottom: 10px;
    padding-right: 20px;
    display: flex;
    justify-content: end;
    font-weight: 600;
`;