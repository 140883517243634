import React, { useState } from 'react';
import styled from 'styled-components';

const TooltipWrapper = styled.div`
    position: relative;
    display: inline-block;
    cursor: pointer;
`;

const TooltipText = styled.div`
    visibility: ${props => (props.visible ? 'visible' : 'hidden')};
    width: 250px;
    background-color: rgba(0,0,0,0.6);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1000; /* Aumente o z-index para garantir que o tooltip apareça acima de outros elementos */
    bottom: 0%; /* Posiciona o tooltip acima do texto */
    right: 100%;
    margin-left: -100px; 
    opacity: ${props => (props.visible ? 1 : 0)};
    font-weight: normal;
    transition: opacity 0.3s, visibility 0.3s; /* Adicione transição para visibilidade */
`;

const Tooltip = ({ text, children }) => {
    const [visible, setVisible] = useState(false);

    return (
        <TooltipWrapper
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
        >
            {children}
            <TooltipText visible={visible}>{text}</TooltipText>
        </TooltipWrapper>
    );
};

export default Tooltip;
