import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaPencilAlt, FaCheck, FaArrowLeft } from 'react-icons/fa';
import { logout } from '../redux/actions';
import { useDispatch } from 'react-redux';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc, updateDoc } from '@firebase/firestore';
import { firebaseConfig } from '../DATABASE/firebaseConfig';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const static_image_profile = 'https://firebasestorage.googleapis.com/v0/b/white-lable-528b0.appspot.com/o/assets%2FPeroWL.png?alt=media&token=4552ff3d-41f4-4715-85b7-1a2b9f7f7e89';

const ProfilePage = () => {
    const [userProfile, setUserProfile] = useState(null);

    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const docRef = doc(db, 'USERS', localStorage.getItem('cpfCLIENTE'));
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setUserProfile(docSnap.data());
                    console.log(userProfile);
                } else {
                    console.error("No such document!");
                }
            } catch (error) {
                console.error('Erro ao obter os dados do usuário:', error);
            }
        };

        fetchData();
    }, [db]);

    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logout());
        window.location.href = '/';
    };

    const [inputsEnabled, setInputsEnabled] = useState({
        usuario: false,
        cpf: false,
        contato: false,
        endereco: false,
        bairro: false,
        cep: false,
        cidade: false,
        estado: false,
    });

    const [inputIcons, setInputIcons] = useState({
        usuario: <FaPencilAlt />,
        endereco: <FaPencilAlt />,
        bairro: <FaPencilAlt />,
        cep: <FaPencilAlt />,
        cidade: <FaPencilAlt />,
        estado: <FaPencilAlt />,
    });

    const [inputValues, setInputValues] = useState({
        usuario: "",
        cpf: "",
        contato: "",
        endereco: "",
        bairro: "",
        cep: "",
        cidade: "",
        estado: "",
    });

    useEffect(() => {
        if (userProfile) {
            setInputValues({
                usuario: userProfile.NAME || "",
                cpf: userProfile.CPF || "",
                contato: userProfile.EMAIL || "",
                endereco: userProfile.ADRESS || "",
                bairro: userProfile.NEIGHBORHOOD || "",
                cep: userProfile.POSTALCODE || "",
                cidade: userProfile.CITY || "",
                estado: userProfile.STATE || "",
            });
        }
    }, [userProfile]);

    const toggleInput = async (inputName) => {
        if (inputsEnabled[inputName]) {
            // Se o input estava ativado e agora será desativado, salve os dados
            try {
                const docRef = doc(db, 'USERS', inputValues.cpf);
                await updateDoc(docRef, {
                    NAME: inputValues.usuario,
                    ADRESS: inputValues.endereco,
                    NEIGHBORHOOD: inputValues.bairro,
                    POSTALCODE: inputValues.cep,
                    CITY: inputValues.cidade,
                    STATE: inputValues.estado,
                });
                console.log("Document successfully updated!");
            } catch (error) {
                console.error("Error updating document: ", error);
            }
        }

        setInputsEnabled({
            ...inputsEnabled,
            [inputName]: !inputsEnabled[inputName],
        });
        setInputIcons({
            ...inputIcons,
            [inputName]: !inputsEnabled[inputName] ? <FaCheck /> : <FaPencilAlt />,
        });
    };

    const handleInputChange = (inputName, value) => {
        setInputValues({
            ...inputValues,
            [inputName]: value,
        });
    };

    const handleGetBack = () => {
        window.location.href = '/home';
    };


    const uploadProfilePicture = async (file, cpf) => {
        const storage = getStorage();
        const storageRef = ref(storage, `profile_pictures/${cpf}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
    
        try {
            await uploadTask;
            console.log('File uploaded successfully');
    
            // Obter a URL de download da imagem
            const downloadURL = await getDownloadURL(storageRef);
            return downloadURL;
        } catch (error) {
            console.error('Error uploading file:', error);
            throw error; // Tratar o erro conforme necessário no componente
        }
    };

    const updateProfilePictureInFirestore = async (cpf, downloadURL) => {
        try {
            const docRef = doc(db, 'USERS', cpf);
            await updateDoc(docRef, {
                PROFILEPICTURE: downloadURL,
            });
            console.log('Profile picture URL updated in Firestore');
        } catch (error) {
            console.error('Error updating profile picture URL:', error);
            throw error; // Tratar o erro conforme necessário no componente
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        
        try {
            // Fazer upload da nova foto para o Storage
            const downloadURL = await uploadProfilePicture(file, inputValues.cpf);
    
            // Atualizar 'PROFILEPICTURE' no Firestore
            await updateProfilePictureInFirestore(inputValues.cpf, downloadURL);
    
            console.log('Document updated successfully with profile picture URL');
            
            // Atualizar o state local com a nova URL da imagem, se necessário
            // setInputValues({
            //     ...inputValues,
            //     PROFILEPICTURE: downloadURL,
            // });
    
            // Ou atualizar diretamente o state userProfile, se necessário
            setUserProfile({
                ...userProfile,
                PROFILEPICTURE: downloadURL,
            });
        } catch (error) {
            console.error('Error handling file change:', error);
            // Tratar o erro conforme necessário no componente
        }
    };
    

    return (
        <Container>
            <ProfileCard>
                <BackIcon onClick={handleGetBack} />
                <InitialContent>
                    <ProfilePicture>
                        <img src={userProfile ? userProfile.PROFILEPICTURE : static_image_profile} alt="Profile Picture" />
                        <ChangePhotoOverlay>
                            <ChangePhotoText>Mude a foto</ChangePhotoText>
                            <FileInput type="file" onChange={handleFileChange} />
                        </ChangePhotoOverlay>
                    </ProfilePicture>
                    <ProfileName>{inputValues.usuario}</ProfileName>
                </InitialContent>

                <ProfileInfo>
                    <h4>Informações do Perfil</h4>
                    <Info>
                        <InfoBox>
                            <h3>Usuário: </h3>
                            <input
                                disabled={!inputsEnabled.usuario}
                                value={inputValues.usuario}
                                onChange={(e) => handleInputChange('usuario', e.target.value)}
                            />
                            <EditIcon onClick={() => toggleInput('usuario')}>
                                {inputIcons.usuario}
                            </EditIcon>
                        </InfoBox>
                        <InfoBox>
                            <h3>CPF: </h3>
                            <input
                                disabled={!inputsEnabled.cpf}
                                value={inputValues.cpf}
                            />
                            <EditIcon onClick={() => toggleInput('cpf')}>
                                {inputIcons.cpf}
                            </EditIcon>
                        </InfoBox>
                        <InfoBox>
                            <h3>Contato: </h3>
                            <input
                                disabled={!inputsEnabled.contato}
                                value={inputValues.contato}
                            />
                            <EditIcon onClick={() => toggleInput('contato')}>
                                {inputIcons.contato}
                            </EditIcon>
                        </InfoBox>
                        <InfoBox>
                            <h3>Endereço: </h3>
                            <input
                                disabled={!inputsEnabled.endereco}
                                value={inputValues.endereco}
                                onChange={(e) => handleInputChange('endereco', e.target.value)}
                            />
                            <EditIcon onClick={() => toggleInput('endereco')}>
                                {inputIcons.endereco}
                            </EditIcon>
                        </InfoBox>
                        <InfoBox>
                            <h3>Bairro: </h3>
                            <input
                                disabled={!inputsEnabled.bairro}
                                value={inputValues.bairro}
                                onChange={(e) => handleInputChange('bairro', e.target.value)}
                            />
                            <EditIcon onClick={() => toggleInput('bairro')}>
                                {inputIcons.bairro}
                            </EditIcon>
                        </InfoBox>
                        <InfoBox>
                            <h3>CEP: </h3>
                            <input
                                disabled={!inputsEnabled.cep}
                                value={inputValues.cep}
                                onChange={(e) => handleInputChange('cep', e.target.value)}
                            />
                            <EditIcon onClick={() => toggleInput('cep')}>
                                {inputIcons.cep}
                            </EditIcon>
                        </InfoBox>
                        <InfoBox>
                            <h3>Cidade: </h3>
                            <input
                                disabled={!inputsEnabled.cidade}
                                value={inputValues.cidade}
                                onChange={(e) => handleInputChange('cidade', e.target.value)}
                            />
                            <EditIcon onClick={() => toggleInput('cidade')}>
                                {inputIcons.cidade}
                            </EditIcon>
                        </InfoBox>
                        <InfoBox>
                            <h3>Estado: </h3>
                            <input
                                disabled={!inputsEnabled.estado}
                                value={inputValues.estado}
                                onChange={(e) => handleInputChange('estado', e.target.value)}
                            />
                            <EditIcon onClick={() => toggleInput('estado')}>
                                {inputIcons.estado}
                            </EditIcon>
                        </InfoBox>
                    </Info>
                </ProfileInfo>
                <LogoutBtn onClick={handleLogout}>Sair</LogoutBtn>
            </ProfileCard>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;

    @media (max-width: 800px) {
        height: 80vh;
        top: 100px;
    }
`;

const ProfileCard = styled.div`
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    width: 800px;
    padding: 20px;
    position: relative;
`;

const BackIcon = styled(FaArrowLeft)`
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 20px;
    cursor: pointer;
`;

const InitialContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
`;

const ProfilePicture = styled.div`
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 10px;
    cursor: pointer;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: filter 0.3s ease;
    }

    &:hover img {
        filter: brightness(70%);
    }
`;

const ChangePhotoOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    transition: opacity 0.3s ease;

    ${ProfilePicture}:hover & {
        opacity: 1;
    }
`;

const ChangePhotoText = styled.p`
    margin: 0;
    font-size: 14px;
`;

const FileInput = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
`;

const ProfileName = styled.h2`
    font-size: 24px;
    margin-bottom: 5px;
`;

const ProfileInfo = styled.div`
    h4 {
        font-size: 18px;
        margin-bottom: 10px;
    }
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;

const InfoBox = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;

    h3 {
        margin: 0;
        margin-right: 10px;
        font-size: 16px;
        margin-bottom: 0;
        width: 100px;
    }

    input {
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 5px;
        font-size: 16px;
        flex-grow: 1;
        margin-right: 10px;
    }
`;

const EditIcon = styled.div`
    cursor: pointer;
    font-size: 18px;
`;

const LogoutBtn = styled.button`
    width: 100%;
    height: 35px;
    border: 0;
    background-color: #f44545;
    color: black;
    font-size: 18px;

    &:hover {
        background-color: #f91d1d;
    }
`;

export default ProfilePage;
