import React, { useState, useEffect } from "react";
import styled from "styled-components";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import Loading from "./Loader";

const firebaseConfig = {
    apiKey: 'AIzaSyCnwSOjrqasUNSCp6UrK2moHd1OtLUMj28',
    authDomain: 'wldata.firebaseapp.com',
    projectId: 'wldata',
    storageBucket: 'wldata.appspot.com',
    messagingSenderId: '86184173654',
    appId: '1:86184173654:web:9463c36b71d142b684dbf7'
};



firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();
const firestore = firebase.firestore();

export default function Validacao() {
    const [document, setDocument] = useState(null);
    const [face, setFace] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [verificado, setVerificado] = useState(false);
    const [enviado, setEnviado] = useState(false);
    const [load, setLoad] = useState(false);


    const handleChangeLoad = () => {
        setLoad(!load);
    }

    useEffect(() => {
        const cpfCliente = localStorage.getItem('cpfCLIENTE');
        if (cpfCliente) {
            setLoad(true);
            const userRef = firestore.collection("USERS").doc(cpfCliente);
            userRef.get().then((doc) => {
                if (doc.exists) {
                    setVerificado(doc.data().VERIFICADO || false);
                    setEnviado(doc.data().DOCSENVIADOS || false);
                } else {
                    console.error("Documento não encontrado.");
                }
            }).catch((error) => {
                console.error("Erro ao obter documento:", error);
            }).finally(() => {
                setLoad(false);
            });
        } else {
            console.error("CPF do cliente não encontrado no localStorage.");
        }
    }, []);

    const handleCaptureDoc = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setDocument(reader.result);
        };
    };

    const handleCaptureFace = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setFace(reader.result);
        };
    };

    const handleUpload = async () => {
        if (document && face) {
            setLoad(true);
            try {
                setUploading(true);

                const docRandomName = Math.random().toString(36).substring(7);
                const docUploadTask = storage.ref(`documentosClientes/documento-${docRandomName}`).putString(document, 'data_url');

                docUploadTask.on(
                    "state_changed",
                    null,
                    (error) => {
                        console.error("Erro ao enviar o documento:", error);
                        alert("Erro ao enviar o documento. Por favor, tente novamente mais tarde.");
                        setLoad(false);
                    },
                    () => {
                        storage.ref("documentosClientes")
                            .child(`documento-${docRandomName}`)
                            .getDownloadURL()
                            .then((docDownloadUrl) => {
                                console.log("URL de download do documento:", docDownloadUrl);

                                const faceRandomName = Math.random().toString(36).substring(7);
                                const faceUploadTask = storage.ref(`documentosClientes/rosto-${faceRandomName}`).putString(face, 'data_url');

                                faceUploadTask.on(
                                    "state_changed",
                                    null,
                                    (error) => {
                                        console.error("Erro ao enviar o rosto:", error);
                                        alert("Erro ao enviar o rosto. Por favor, tente novamente mais tarde.");
                                        setLoad(false);
                                    },
                                    () => {
                                        storage.ref("documentosClientes")
                                            .child(`rosto-${faceRandomName}`)
                                            .getDownloadURL()
                                            .then((faceDownloadUrl) => {
                                                console.log("URL de download do rosto:", faceDownloadUrl);
                                                setUploadSuccess(true);

                                                const cpfCliente = localStorage.getItem('cpfCLIENTE');
                                                if (cpfCliente) {
                                                    const userRef = firestore.collection("USERS").doc(cpfCliente);
                                                    userRef.update({
                                                        DOCCLIENT: docDownloadUrl,
                                                        FACECLIENT: faceDownloadUrl,
                                                        DOCSENVIADOS: true
                                                    }).then(() => {
                                                        console.log("Documentos atualizados no Firestore.");
                                                        setEnviado(true);
                                                    }).catch((error) => {
                                                        console.error("Erro ao atualizar documentos no Firestore:", error);
                                                        alert("Erro ao atualizar documentos no Firestore. Por favor, tente novamente mais tarde.");
                                                    }).finally(() => {
                                                        setLoad(false);
                                                    });
                                                } else {
                                                    console.error("CPF do cliente não encontrado no localStorage.");
                                                    setLoad(false);
                                                }
                                            })
                                            .catch((error) => {
                                                console.error("Erro ao obter a URL do rosto:", error);
                                                alert("Erro ao obter a URL do rosto. Por favor, tente novamente mais tarde.");
                                                setLoad(false);
                                            });
                                    }
                                );
                            })
                            .catch((error) => {
                                console.error("Erro ao obter a URL do documento:", error);
                                alert("Erro ao obter a URL do documento. Por favor, tente novamente mais tarde.");
                                setLoad(false);
                            });
                    }
                );
            } catch (error) {
                console.error("Erro ao enviar os documentos:", error);
                alert("Erro ao enviar os documentos. Por favor, tente novamente mais tarde.");
                setLoad(false);
            } finally {
                setUploading(false);
            }
        } else {
            alert("Por favor, capture tanto o documento quanto o rosto antes de enviar.");
        }
    };

    return (
        <ValidacaoContainer>
            <Loading load={load} />
            <ValidacaoCenter>
                {verificado && enviado ? (
                    <ValidacaoSuccess>
                        <h3>Documentos Verificados!</h3>
                        <p>Seus documentos foram enviados e validados com sucesso.</p>
                    </ValidacaoSuccess>
                ) : enviado ? (
                    <ValidacaoSuccess>
                        <h3>Documentos Enviados!</h3>
                        <p>Seus documentos foram enviados e estão em processo de validação.</p>
                    </ValidacaoSuccess>
                ) : (
                    <>
                        <ValidacaoHeader>
                            <h3>FAÇA A VALIDAÇÃO DA SUA CONTA PARA PODER REALIZAR SAQUES</h3>
                            <div>
                                <span>DOCUMENTOS ACEITOS</span>
                                <ul>
                                    <li>RG</li>
                                    <li>CNH</li>
                                    <li>PASSAPORTE</li>
                                </ul>
                            </div>
                        </ValidacaoHeader>
                        <ValidacaoUploadFiles>
                            <UploadDocument>
                                <label htmlFor="docInput">Tire uma foto do documento:</label>
                                {document && <ImagePreview src={document} alt="Documento capturado" />}

                                <input
                                    id="docInput"
                                    type="file"
                                    accept="image/*"
                                    capture="environment"
                                    onChange={handleCaptureDoc}
                                />
                            </UploadDocument>

                            <UploadFace>
                                <label htmlFor="faceInput">Tire uma foto do rosto:</label>

                                {face && <ImagePreview src={face} alt="Rosto capturado" />}
                                <input
                                    id="faceInput"
                                    type="file"
                                    accept="image/*"
                                    capture="environment"
                                    onChange={handleCaptureFace}
                                />
                            </UploadFace>
                        </ValidacaoUploadFiles>
                        <ValidacaoConfirmation>
                            <button disabled={uploading || uploadSuccess} onClick={handleUpload}>
                                {uploading ? "Enviando..." : uploadSuccess ? "Documentos Enviados!" : "ENVIAR DOCUMENTOS"}
                            </button>
                        </ValidacaoConfirmation>
                        <ValidacaoFooter>
                            <span>OS DOCUMENTOS SÃO VALIDADOS EM ATÉ 7 DIAS ÚTEIS<br />TENDO ALGUMA DÚVIDA, ENTRE EM CONTATO</span>
                        </ValidacaoFooter>
                    </>
                )}

            </ValidacaoCenter>
        </ValidacaoContainer>
    );
}


const ValidacaoSuccess = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

const ValidacaoContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: aliceblue;
    box-sizing: border-box;
    padding: 50px 30px 50px 40px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    gap: 20px;
    position: relative;

    @media (max-width: 800px){
        flex-direction: column;
        justify-content: center;
        padding: 20px 10px 50px 10px;
    }
`;

const ValidacaoCenter = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ValidacaoHeader = styled.div`
    margin-bottom: 20px;
    color: rgba(0,0,0,0.7);

    h3{
        font-size: 24px;
        font-weight: bold;
        margin: 0;
    }

    div{
        padding-left: 20px;
        span{
            display: block;
            color: rgba(255,0,0,0.7);
            padding-top: 20px;
            font-weight: 600;
        }

        ul{
            margin-top: 10px;

            li{
                font-style: italic;
            }
        }
    }

    @media (max-width: 800px){
        h3{
            font-size: 24px;
            font-weight: bold;
            margin: 0;
            text-align: center;
        }

        div{
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            align-items: start;
            margin-top: 20px;
            ul{
                margin-top: 10px;
            }
        }
    }
`;

const ValidacaoUploadFiles = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    
    label {
        font-size: 18px;
        margin-bottom: 10px;
    }

    input {
        font-size: 16px;
    }

    @media (max-width: 800px){
        width: 100%;
        flex-direction: column;
        padding: 30px;
        box-sizing: border-box;
        overflow: hidden;
    }
`;

const ValidacaoConfirmation = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;

    button{
        width: 50%;
        height: 40px;
        border: 0;
        border-radius: 8px;
        background-color: rgba(40, 220, 10, 0.8);
        font-weight: 600;
        color: rgba(0,0,0,0.6);
        transition: .3s;

        &:hover{
            color: rgba(0,0,0,1);
            box-shadow: 2px 3px 4px rgba(0,0,0,0.6);
        }
    }

    @media (max-width: 800px){
        margin-top: 0px;

        button{
            width: 100%;
        }
    }
`;

const ValidacaoFooter = styled.div`
    margin-top: 50px;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: start;

    span{
        font-weight: 600;
        color: rgba(0,0,0,0.6);
        text-align: center;
    }
`;

const UploadDocument = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 500px;
    height: 600px;
    position: relative;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 2px 2px 3px rgba(0,0,0,0.6);
    background-color: rgba(0,0,0,0.1);
    overflow: hidden;
    box-sizing: border-box;
    text-align: center;

    label {
        font-size: 24px;
        color: rgba(0,0,0,0.3);
    }

    input {
        cursor: pointer;
    }

    @media (max-width: 800px){
        width: 100%;
        height: 450px;
            
        label {
            font-size: 20px;
        }

        input {
            width: 100%;
        }
    }
`;

const UploadFace = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 500px;
    height: 600px;
    position: relative;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 2px 2px 3px rgba(0,0,0,0.6);
    background-color: rgba(0,0,0,0.1);
    overflow: hidden;
    box-sizing: border-box;
    text-align: center;

    label {
        font-size: 24px;
        color: rgba(0,0,0,0.3);
    }

    input {
        cursor: pointer;
    }

    @media (max-width: 800px){
        width: 100%;
        height: 450px;
            
        label {
            font-size: 20px;
        }

        input {
            width: 100%;
        }
    }
`;

const ImagePreview = styled.img`
    width: 100%;
    height: 80%;
    object-fit: contain;
`;
