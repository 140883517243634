import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, updateDoc, arrayUnion, getDoc } from "@firebase/firestore";
import { firebaseConfig } from "../DATABASE/firebaseConfig";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import Loading from "./Loader";
import Table from "./Table";
import ReloadButton from "./ReloadButton";
import styled from "styled-components";

export default function Token() {
    const [tokenQtd, setTokenQtd] = useState("1");
    const [paymentMethod, setPaymentMethod] = useState("PIX");
    const static_value_coin = 67.23;
    const [showModal, setShowModal] = useState(false);
    const [cpfConfirmacao, setCpfConfirmacao] = useState("");
    const [senhaConfirmacao, setSenhaConfirmacao] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [load, setLoad] = useState(false);
    const [hasAgreed, setHasAgreed] = useState(false);

    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);


    const [tokenValue, setTokenValue] = useState(0);


    const fetchData = async () => {
        setLoad(true);
        try {
            const docRef = doc(db, "USERS", localStorage.getItem("cpfCLIENTE"));
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setUserData(docSnap.data());
            } else {
                console.error("No such document!");
            }
            setLoad(false);

        } catch (error) {
            setLoad(false);
            console.error("Erro ao obter os dados do usuário:", error);
        }
    };

    const fetchSystemVariables = async () => {
        setLoad(true);
        try {
            const docRef = doc(db, "SYSTEM_VARIABLES", 'TOKEN');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setTokenValue(docSnap.data().TOKEN_VALUE);
            } else {
                console.error("token value não encontrado!");
            }
            setLoad(false);

        } catch (error) {
            setLoad(false);
            console.error("Erro ao obter o token value:", error);
        }
    };

    

    useEffect(() => {
        fetchData();
        fetchSystemVariables();
    }, [db]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        if (value === "" || Number(value) > 0) {
            setTokenQtd(value);
        }
    };

    const formatNumber = (number) => {
        return parseFloat(number).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    const handlePaymentMethodChange = (e) => {
        setPaymentMethod(e.target.value);
    };

    const handleCpfChange = (e) => {
        setCpfConfirmacao(e.target.value);
    };

    const handleSenhaChange = (e) => {
        setSenhaConfirmacao(e.target.value);
    };

    const auth = getAuth(app);

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    function gerarStringAleatoria() {
        let tamanho = 4;
        const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+';
        let resultado = '';
        for (let i = 0; i < tamanho; i++) {
            resultado += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
        }
        return resultado;
    }

    const handlePostTokenPurchase = async () => {
        setIsLoading(true); // Ativar o estado de carregamento

        const today = new Date();
        const nextYear = new Date();
        nextYear.setFullYear(today.getFullYear() + 1);
        let compraInfo = [];

        if (paymentMethod === 'SALDO INDICAÇÃO') {

            if ((parseFloat(tokenQtd) * static_value_coin) > userData.INDICATIONBUDGET) {
                alert('SALDO DE INDICAÇÃO NÃO SUFICIENTE')
                return;
            }
            compraInfo = {
                ALLOWSELL: formatDate(nextYear),
                COINS: parseInt(tokenQtd),
                COINVALUE: tokenValue,
                PURCHASEDATE: formatDate(today),
                IDCOMPRA: gerarStringAleatoria(),
                STATUS: true,
                TOTALSPENT: parseFloat(tokenQtd) * tokenValue,
                PAYMENTMETHOD: paymentMethod,
                VISTO: true,
                LUCRO_OBTIDO: 0,
            };
        } else {
            compraInfo = {
                ALLOWSELL: formatDate(nextYear),
                COINS: parseInt(tokenQtd),
                COINVALUE: tokenValue,
                PURCHASEDATE: formatDate(today),
                IDCOMPRA: gerarStringAleatoria(),
                STATUS: false,
                TOTALSPENT: parseFloat(tokenQtd) * tokenValue,
                PAYMENTMETHOD: paymentMethod,
                LUCRO_OBTIDO: 0,
                VISTO: false,
            };
        }

        try {
            setLoad(true);
            console.log("Trying to get document with CPF:", cpfConfirmacao);
            const userDoc = await getDoc(doc(db, "USERS", cpfConfirmacao));

            if (!userDoc.exists()) {
                console.error("User data not found for CPF:", cpfConfirmacao);
                setErrorMessage("User data not found");
                setIsLoading(false); // Desativar o estado de carregamento
                setLoad(false);
                alert("Confirmação inválida");

                return;
            }

            const userData = userDoc.data();
            const email = userData.EMAIL;
            console.log("User data found:", userData);

            await signInWithEmailAndPassword(auth, email, senhaConfirmacao);
            console.log("Confirmation successful");

            const userDocRef = doc(db, "USERS", cpfConfirmacao);

            if (compraInfo.PAYMENTMETHOD === 'SALDO INDICAÇÃO') {
                const newIndicationBudget = userData.INDICATIONBUDGET - (parseFloat(tokenQtd) * static_value_coin);

                await updateDoc(userDocRef, {
                    CONTRATOS: arrayUnion(compraInfo),
                    INDICATIONBUDGET: newIndicationBudget,
                });
                alert("Compra Feita com sucesso!");
            } else {
                await updateDoc(userDocRef, {
                    CONTRATOS: arrayUnion(compraInfo),
                });
                alert("Sua compra foi solicitada com sucesso, aguarde a confirmação");
            }

            setShowModal(false);
            setLoad(false);
            fetchData();

        } catch (error) {
            console.error("Error confirming with email and password", error);
            setErrorMessage(error.message);
            setLoad(false);
            alert("Ouve Algum erro, tente novamente mais tarde");

        } finally {
            setIsLoading(false); // Desativar o estado de carregamento
            setLoad(false);
        }
    };



    const handleCancel = () => {
        setShowModal(false);
    };

    const handleReload = () => {
        fetchData();
    }

    const handleAgreeChange = (e) => {
        setHasAgreed(e.target.checked);
    };

    return (
        <TokenContainer>
            <FirstDiv>
                <h4>Saldo de Indicação</h4>
                <h4 className="value-different">U$ {userData && formatNumber(userData.INDICATIONBUDGET)}</h4>
            </FirstDiv>

            <BuyTokenArea>
                <BuyTokenBox>
                    <p>Valor Unitário Compra</p>
                    <p>U$ {formatNumber(tokenValue)}</p>
                </BuyTokenBox>

                <BuyTokenBox>
                    <p>Quantidade de Tokens</p>
                    <input
                        value={tokenQtd}
                        min="1"
                        placeholder="*Digite..."
                        id="token-qtd"
                        type="number"
                        onChange={handleInputChange}
                    />
                </BuyTokenBox>
                <BuyTokenBox>
                    <p>Taxa da Compra</p>
                    <p>U$ 0,00</p>
                </BuyTokenBox>
                <BuyTokenBox>
                    <p>Valor Total</p>
                    <p>U$ {formatNumber((tokenQtd * tokenValue).toFixed(2))}</p>
                </BuyTokenBox>

                <BuyTokenBox>
                    <p>Forma de Pagamento</p>
                    <select
                        id="payment-method"
                        value={paymentMethod}
                        onChange={handlePaymentMethodChange}
                    >
                        <option value="PIX">PIX</option>
                        <option value="DEPOSITO BANCÁRIO">DEPOSITO BANCÁRIO</option>
                        <option value="SALDO INDICAÇÃO">SALDO DE INDICAÇÃO</option>
                    </select>
                </BuyTokenBox>
            </BuyTokenArea>

            <BuyBtnDiv>
                <button onClick={() => setShowModal(true)}>Comprar Token(s)</button>
            </BuyBtnDiv>


            <ReloadButton handleReload={handleReload} />

            <Table userData={userData} />

            {showModal && (
                <ModalConfirmacao>
                    <BoxConfirmacao>
                        <Loading load={load} />

                        <CloseModalBtn onClick={handleCancel}>X</CloseModalBtn>

                        <h3>Confirmação de Transação</h3>

                        {errorMessage && <p className="error-message">{errorMessage}</p>}

                        <ConfirmationText>
                            <TextContrato>
                                Antes de confirmar a compra, por favor leia o seguinte texto:
                                <br /><br />
                                "CONTRATO DE COMPRA DE TOKENS

                                IDENTIFICAÇÃO DAS PARTES CONTRATANTES
                                Pelo presente instrumento particular de um lado, {userData.NAME} inscrito no CPF sob o nº {userData.CPF}, residente e domiciliado à {userData.ADRESS}, doravante denominado COMPRADOR, e de outro lado, a empresa [Nome da Empresa], com sede à [Endereço da Empresa], inscrita no CNPJ sob o nº [CNPJ da Empresa], doravante denominada VENDEDORA, têm entre si justo e contratado o seguinte:

                                OBJETO DO CONTRATO
                                Cláusula 1ª. O presente contrato tem como objeto a compra de {formatNumber(tokenQtd)} tokens ao valor unitário de U$ {formatNumber(tokenValue)}, totalizando o valor de U$ {formatNumber(tokenQtd * tokenValue)}.

                                CONDIÇÕES DE PAGAMENTO
                                Cláusula 2ª. O pagamento será realizado através de {paymentMethod}, conforme as condições acordadas entre as partes.

                                OBRIGAÇÕES DO COMPRADOR
                                Cláusula 3ª. O COMPRADOR se compromete a utilizar os tokens adquiridos de acordo com as finalidades estabelecidas pela VENDEDORA e a respeitar todas as normas e regulamentos aplicáveis.

                                DIREITOS DA VENDEDORA
                                Cláusula 4ª. A VENDEDORA se reserva o direito de alterar as condições de uso dos tokens, bem como suas funcionalidades, mediante aviso prévio ao COMPRADOR.

                                PRAZO E RESCISÃO
                                Cláusula 5ª. Este contrato é válido por um período de 1 (um) ano, a contar da data de sua assinatura. O contrato poderá ser rescindido por qualquer uma das partes, mediante notificação por escrito com antecedência mínima de 30 (trinta) dias.

                                DISPOSIÇÕES GERAIS
                                Cláusula 6ª. As partes elegem o foro da comarca de [Comarca], para dirimir quaisquer dúvidas ou litígios oriundos deste contrato, renunciando a qualquer outro, por mais privilegiado que seja.
                            </TextContrato>
                        </ConfirmationText>

                        <ConfirmationCheck>
                            <input
                                type="checkbox"
                                id="agreeCheckbox"
                                checked={hasAgreed}
                                onChange={handleAgreeChange}
                            />
                            <label htmlFor="agreeCheckbox">Eu li e aceito os termos e condições</label>
                        </ConfirmationCheck>

                        <ConfirmationBoxInput>
                            <label htmlFor="CPFUser">CPF</label>
                            <input
                                type="text"
                                placeholder="000.000.000-00"
                                id="CPFUser"
                                value={cpfConfirmacao}
                                onChange={handleCpfChange}
                            />
                        </ConfirmationBoxInput>

                        <ConfirmationBoxInput>
                            <label htmlFor="SENHAUser">SENHA</label>
                            <input
                                placeholder=""
                                id="SENHAUser"
                                type="password"
                                value={senhaConfirmacao}
                                onChange={handleSenhaChange}
                            />
                        </ConfirmationBoxInput>

                        <ButtonConfirm
                            onClick={handlePostTokenPurchase}
                            className="buttonConfirm"
                            disabled={!hasAgreed} // Botão desativado até que o usuário aceite os termos
                        >
                            CONFIRMAR COMPRA
                        </ButtonConfirm>

                    </BoxConfirmacao>
                </ModalConfirmacao>
            )}


            {
                isLoading && (
                    <div className="loading-screen">
                        <div className="loading-spinner"></div>
                        <p>Carregando...</p>
                    </div>
                )
            }
        </TokenContainer >

    );
}
const TokenContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    padding: 20px 30px 100px 30px;
    box-sizing: border-box;
   background-color: aliceblue;
    overflow: hidden;
    position: relative;
    @media (max-width: 1382px){
        padding-top: 60px;
        padding-right: 20px;
        padding-left: 20px;
    }
`;

const FirstDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    gap: 100px;
    height: 180px;
    color: black;
    background: linear-gradient(to right ,#87a70a, #b8f500, #87a70a);
    box-shadow: 0px 1px 3px rgba(0,0,0,0.5);
    filter: drop-shadow(1px 1px 20px rgba(255, 255, 255, 0.3));
    transition: .3s;

    h4{
        font-size: 32px;
    }

    @media (max-width: 1382px){
        box-shadow: 0px 1px 3px rgba(0,0,0,0);
        background: transparent;
        flex-direction: column;
        gap: 5px;
    
        h4{
            font-size: 32px;
        }
    }
`;

const BuyTokenArea = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;

    @media (max-width: 1382px){
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

const BuyTokenBox = styled.div`
    width: 350px;
    padding: 40px;
    background: white;
    display: flex;
    filter: drop-shadow(1px 1px 20px rgba(0, 0, 0, 0.2));
    box-shadow: 3px 2px 5px rgba(0,0,0,0.6);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: .3s;
    gap: 25px;

    p{
        font-size: 22px;
        font-weight: 600;
        color: #000814;
        margin:0;
    }

    #token-qtd{
        width: 100%;
        padding-left: 10px;
        box-sizing: border-box;
        border: 0;
        height: 35px;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);    
        filter: drop-shadow(1px 1px 20px rgba(0, 0, 0, 0.1));
    }

    #payment-method{
        width: 250px;
        padding-left: 10px;
        box-sizing: border-box;
        border: 0;
        height: 35px;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
        filter: drop-shadow(1px 1px 20px rgba(0, 0, 0, 0.1));
    }


`;

const BuyBtnDiv = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;

    button{
        transition: .3s;
        width: 100%;
        height: 40px;
        border: 2px solid #001d3d;
        background: linear-gradient(to right ,#87a70a, #b8f500, #87a70a);
        font-size: 18px;
        color: black;
        font-weight: 600;

        &:hover{
            color: white;
            border: 2px solid black;
            background-color: #001d3d;
            font-size: 18px;
            font-weight: 600;
            text-shadow: 1px 2px 1px rgba(0,0,0,0.4);
        }
    }
`;


const ModalConfirmacao = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 999;
    left: 0;
    background-color: rgba(0, 0, 0, 0.418);
    display: flex;
    justify-content: center;
    align-items: start;

    padding-top: 100px;
`;

const BoxConfirmacao = styled.div`
    background-color: rgb(241, 249, 250);
    max-width: 90%;
    height: max-content;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.514);
    border-radius: 20px;
    padding: 40px 50px;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;

    position: relative;

    h3{
        margin-bottom: 20px;
    }
`;

const CloseModalBtn = styled.button`
        background-color: rgba(252, 76, 76, 0);
        position: absolute;
        top: 10px;
        right: 10px;
        border: 0;
        font-weight: 600;

        &:hover{
            background-color: rgba(252, 76, 76, 0.9);
            border-radius: 20px;
        }
`;

const ConfirmationText = styled.div`

`;

const TextContrato = styled.p`
    text-align: justify;
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;
`;

const ConfirmationCheck = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    gap: 5px;
`;

const ConfirmationBoxInput = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;

    label{
        font-size: 24px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.644);
    }

    input{
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.11);
        border-radius: 8px;
        text-align: center;
        height: 40px;
        font-size: 18px;
        box-sizing: border-box;
    }
`;

const ButtonConfirm = styled.button`
    margin-top: 10px;
    width: 300px;
    border: 2px solid transparent;
    background-color: rgb(15, 216, 15);
    border-radius: 20px;
    font-weight: 600;
    transition: .3s;
    color: aliceblue;

    &:hover{
        margin-top: 10px;
        color: black;
        width: 300px;
        border: 2px solid black;
        background-color: rgb(0, 255, 0);
        border-radius: 20px;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.185);
    }
`