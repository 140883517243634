import React from 'react'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import { Provider } from 'react-redux';
import store from './redux/store'; // Importe o store do Redux
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}> {/* Passe o store como propriedade para o Provider */}
      <App />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
