import styled from 'styled-components';

export const SaquesContainer = styled.div`
    width: 100%;
    padding: 20px 50px 200px 50px;
    background-color: ${props => props.colorMode ? 'rgb(9, 1, 49)' : 'whitesmoke'};
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    position: relative;

    @media (max-width: 800px) {
        padding: 20px 50px 100px 50px;
    }
`;

export const FirstDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 100px;
    align-items: center;
    font-weight: 600;

    button {
        height: 40px;
        border: 0;
        background-color: rgba(9, 180, 248, 0.753);
        padding: 0px 20px 0px 40px;
        font-weight: 600;
        color: white;
        transition: .3s;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        text-align: center;
        gap: 10px;
        position: relative;

        &:hover {
            background-color: rgb(9, 180, 248);
        }

        span {
            margin: 0;
            transform: scale(2);
            position: absolute;
            left: 20px;
            top: 7px;
        }
    }

    @media (max-width: 800px) {
        flex-direction: column;
        height: auto;

        button {
            width: 100%;
            margin-top: 10px;
            padding: 0px 20px 0px 40px;
            position: relative;

            span {
                left: 10px;
                top: 5px;
            }
        }
    }
`;

export const Info = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 50px;
    font-weight: 600;
    font-size: 22px;
    color: ${props => props.colorMode ? 'rgba(255, 255, 255, 0.719)' : 'rgba(0, 0, 0, 0.719)'};

    @media (max-width: 800px) {
        font-size: 14px;
        margin-top: 30px;
        text-align: justify;
    }
`;

export const GetMoreClients = styled.div`
    margin-top: 50px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: rgba(226, 226, 6, 0.795);
    border-radius: 4px;
    height: 70px;

    span {
        font-weight: 600;
    }

    button {
        background-color: rgba(240, 248, 255, 0);
        border: 0;
        padding: 0;
        font-weight: 600;
        color: rgb(17, 0, 255);
    }

    @media (max-width: 800px) {
        padding: 10px 20px;
        flex-direction: column;
        margin-top: 30px;
        height: max-content;
        font-size: 14px;
        border: 2px solid white;
    }
`;

export const InfoBoxes = styled.div`
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @media (max-width: 800px){
        margin-top: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
`;

export const InfoBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    height: 130px;
    color: white;
    font-weight: 600;
    font-size: 22px;
    text-align: center;
    background-color: ${props => props.color};
    p {
        margin: 0;
    }

    @media (max-width: 800px) {
        height: 100px;
        font-size: 14px;
    }
`;

export const TableSaques = styled.div`
    overflow-x: auto;
    margin-top: 20px;

    table {
        width: 100%;
        border-collapse: collapse;
        background-color: #fff;
        border-radius: 4px;
        overflow: hidden;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    th, td {
        padding: 12px 15px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    tr{
        position: relative;
    }

    th {
        background-color: #f2f2f2;
    }

    tbody tr:last-child td {
        border-bottom: none;
    }

    .tr-saques td {
        font-weight: 500;
    }
`;

export const ModalSaque = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ModalContent = styled.div`
    width: 1250px;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    // padding: 20px 30px;
    border-radius: 3px;
    position: relative;
    display: flex;
    flex-direction: column;
    
`;

export const ContentHeader = styled.div`
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 15px 20px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    justify-content: space-between;
    align-items: center;
    p{
        margin: 0;
        font-weight: 600;
        color: rgba(0,0,0,0.6);
    }

    div{
        width:20px;
        display: flex;
        justify-content: center;
        align-items: center;

        img{
            width: 100%;
            cursor: pointer;
            transition: .3s;

            &:hover{
                transform: scale(1.5);
            }
        }
    }
`;


export const ContentBody = styled.div`
    height: 200px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p{
        font-size: 24px;
        font-weight: 600;
        color: rgba(44, 188, 255, 1);
        margin: 0;
        text-shadow: 1px 1px 2px rgba(0,0,0,0.4)
    }

    span{
        margin-top: 20px;
        font-weight: 600;
        color: rgba(0,0,0,0.6);
    }
`;

export const ModalTitle = styled.div`
    width: 100%;
    justify-content: center;
    display: flex;
    font-weight: 800;
    color: rgba(0,0,0,0.8);
    text-shadow: 1px 1px 1px rgba(0,0,0,0.6);
`;

export const ContentInput = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    box-sizing: border-box;

    span{
        font-weight: 600;
        color: rgba(0,0,0,0.8);
    }

    input{
        margin-top: 5px;
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: 3px;
        height: 35px;
        padding-left: 30px;
        font-weight: 600;
    }
`;


export const ModalButtons = styled.div`
    height: 80px;
    padding: 10px;
    border-top: 1px solid rgba(0,0,0,0.2);
    display: flex;
    align-items: end;
    justify-content: end;
    gap: 10px;

`;

export const ButtonModal = styled.button`
    width: 130px;
    height: 40px;
    border: 0;
    border-radius: 4px;
    background-color: ${props => props.color};
    color: white;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
    transition: .3s;

    &:hover{
        background-color: ${props => props.colorH};
    }
`;

export const DownSaque = styled.button`
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: transparent;
    border: 0;

    img{
        width: 35px;
        opacity: 0.7;
        transition: .3s;
        &:hover{
            opacity: 1;
        
        }
    }
`;


export const TipoSaque = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    div{
        display: flex;
        align-items:center;
        justify-content: center;
        gap: 10px;

        span{
            margin: 0;
            padding: 0;
        }

        input{
            margin: 0;
        }
    }
`;

export const TipoSaqueDiv = styled.div`

`;