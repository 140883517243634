import React, { useState } from "react";
import styled from 'styled-components';
import ModalGerarPagamento from './ModalGerarPagamento'

const payIcon = 'https://firebasestorage.googleapis.com/v0/b/wldata.appspot.com/o/down-square-3-svgrepo-com.png?alt=media&token=86a34f5a-ad8f-41c3-b1e1-479b0fd97250';



export const consultarALLOWSELL = (dateString) => {
  // Split the input date string to get day, month, and year
  const [day, month, year] = dateString.split('/').map(Number);
  
  // Create a Date object for the input date
  const inputDate = new Date(year, month - 1, day); // Note: months are 0-indexed in JS Date
  
  // Get the current date
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set hours to 0 to compare only the date part

  // Compare dates
  return today < inputDate;
}


const Table = ({ userData }) => {
  const static_value_coin = userData ? userData.COIN_VALUE_ATUAL : 0;
  const [showModal, setShowModal] = useState(false);
  const [payModalMethod, setPayModalMethod] = useState('PIX')

  const formatNumber = (number) => {
    return parseFloat(number).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  const contratos = userData && Array.isArray(userData.CONTRATOS) ? userData.CONTRATOS : [];

  const handleOpenModal = (payMethod) => {
    setShowModal(true);
    setPayModalMethod(payMethod);
  };

  return (

    <>
      <TableContainer>
        <StyledTable>
          <thead>
            <tr>
              <StyledTh>Cód</StyledTh>
              <StyledTh>Data da Compra</StyledTh>
              <StyledTh>Qtde. Tokens</StyledTh>
              <StyledTh>Valor Pago</StyledTh>
              <StyledTh>Lucro Obtido</StyledTh>
              <StyledTh>Valor Total</StyledTh>
              <StyledTh>Recompra até</StyledTh>
              <StyledTh>Status</StyledTh>
              <StyledTh>Pagamento</StyledTh>
            </tr>
          </thead>
          <tbody>
            {contratos.map((contrato, index) => (
              <StyledTr key={`contrato-${index}`}>
                <td>{index}</td>
                <td>{contrato.PURCHASEDATE}</td>
                <td>{contrato.COINS}</td>
                <td>U${formatNumber(parseFloat(contrato.COINS) * contrato.COINVALUE)}</td>
                <td> {(contrato.VISTO) ? 'U$' + formatNumber((parseFloat(contrato.TOTALSPENT) * (contrato.LUCRO_OBTIDO/100))) : 'PENDENTE'}</td>
                <td> {(contrato.VISTO) ? 'U$' + formatNumber((parseFloat(contrato.TOTALSPENT) * (contrato.LUCRO_OBTIDO/100)) +  ((contrato.COINS) * (contrato.COINVALUE))) : 'PENDENTE'}</td>
                <td>{consultarALLOWSELL(contrato.ALLOWSELL) ? contrato.ALLOWSELL : "CONTRATO ENCERRADO"}</td>
                <td>
                  <StyledStatus>
                    {(contrato.VISTO) ? (consultarALLOWSELL(contrato.ALLOWSELL) ? (contrato.STATUS ? 'VALORIZANDO' : 'NEGADO') : "CONTRATO ENCERRADO") : 'PENDENTE'}
                  </StyledStatus>
                </td>
                <td>
                  {contrato.PAYMENTMETHOD && !contrato.VISTO ? (
                    <ShowPayModal onClick={() => { handleOpenModal(contrato.PAYMENTMETHOD) }} src={payIcon} />

                  ) : (
                    contrato.STATUS ? (
                      <span className="pagamentAceito">{contrato.PAYMENTMETHOD} Aceito!</span>
                    ) : (
                      <span className="pagamentoNegado">{contrato.PAYMENTMETHOD} Negado!</span>
                    )
                  )}
                </td>

              </StyledTr>
            ))}
          </tbody>
        </StyledTable>
      </TableContainer>
      {showModal && (
        <ModalGerarPagamento payModalMethod={payModalMethod} setShowModal={setShowModal} />
      )}
    </>


  );
};

export default Table;

const ShowPayModal = styled.img`
  width: 45px;
  height: 45px;
  cursor: pointer;

`;

const TableContainer = styled.div`
  width: 100%;
  border-radius: 20px;
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const StyledTh = styled.th`
  background-color: rgb(255, 255, 255);
  font-size: 19px;
  font-weight: normal;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: rgba(15, 15, 15, 0.856);
  padding: 10px;
  text-align: left;
    text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const StyledTr = styled.tr`
  &:nth-child(even) {
    background-color: #CCE6FF;
  }

  td {
    padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    .pagamentoNegado{
      color: red;
      font-weight: 600;
    }

    .pagamentAceito{
      color: green;
      font-weight: 600;
    }
  }
`;

const StyledStatus = styled.span`
  display: inline-block;
  padding: 6px 10px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 20px;
  background-color: greenyellow;
  color: black;
`;
