import React from "react";
import styled from "styled-components";

export default function ReloadButton({ handleReload }) {

    const handleClick = () => {
        handleReload();
    }

    return (
        <ReloadArea>
            <button onClick={handleClick}>RECARREGAR</button>
        </ReloadArea>
    )
}

const ReloadArea= styled.div`
    width: 100%;
    padding-top: 20px;
    display: flex;
    justify-content: end;
    padding-right: 20px;
    
    button{
        background-color: transparent;
        margin: 0;
        border: 2px solid transparent;
        transitioon: .3s;
        border-radius: 8px;
        color: rgba(0,0,0,0.5);
        font-weight: 600;
        &:hover{
            color: rgba(0,0,0,0.8);
        }
    }

`;