import React from "react";
import styled from "styled-components";


//home
const HomeDescription = () => (
    <DescriptionContainer>
        <ExplicacaoInicial>
            <span>HOME (PÁGINA INICIAL)</span>, onde você pode acompanhar o progresso da sua conta. Monitore a <span>quantidade de TOKENS comprados</span>, visualize o <span>saldo total</span> da sua conta, consulte o saldo de recompra (saldo de contratos expirados) e o saldo de indicação. Além disso, tenha acesso a <span>gráficos</span> em tempo real do Bitcoin e do Tether, e acompanhe todas as suas compras de <span>TOKENS</span>.
        </ExplicacaoInicial>

    </DescriptionContainer>

);


const TokenDescription = () => (
    <DescriptionContainer>
        <ExplicacaoInicial>
            Esta área permite que você <span>gerencie seus contratos</span> de compra de <span>TOKENS</span> de forma eficiente. Você pode calcular o valor correspondente para diferentes quantidades de <span>TOKENS</span>, facilitando a tomada de decisões de investimento. Além disso, você tem acesso a um <span>histórico completo</span> de todas as suas transações de compra, permitindo que você monitore e analise seu desempenho ao longo do tempo.
        </ExplicacaoInicial>
    </DescriptionContainer>
);



const SaquesDescription = () => (
    <DescriptionContainer>
        <ExplicacaoInicial>
            Na seção de <span>Saques</span>, você pode gerenciar suas solicitações de retirada de fundos. Esta área permite que você visualize seu saldo disponível para saque, inicie novas solicitações de retirada e acompanhe o status das solicitações anteriores. O processo é simplificado para garantir que você possa acessar seus fundos de maneira rápida e segura.
        </ExplicacaoInicial>
    </DescriptionContainer>
);


const NewsDescription = () => (
    <DescriptionContainer>
        <ExplicacaoInicial>
            A seção <span>News</span> fornece as últimas atualizações e notícias relevantes para os usuários. Aqui, você pode visualizar uma lista de notícias organizadas cronologicamente, com detalhes como títulos, imagens e conteúdos expansíveis para leitura completa. Utilize o botão de recarregar para obter as notícias mais recentes e mantenha-se informado sobre as novidades e eventos importantes.
        </ExplicacaoInicial>
    </DescriptionContainer>
);


const DownloadsDescription = () => (
    <DescriptionContainer>
        <ExplicacaoInicial>
            A seção disponibiliza o download do documento da empresa que explica sobre os <span>TOKENS</span>
        </ExplicacaoInicial>
    </DescriptionContainer>
);

const PerfilDescription = () => (
    <DescriptionContainer>
        <ExplicacaoInicial>
            Na seção <span>Perfil</span>, você pode visualizar e editar suas informações pessoais. Gerencie dados como nome, CPF, contato, endereço, e outras informações relevantes. Além disso, você pode atualizar essas informações de forma fácil e segura, garantindo que seus dados estejam sempre corretos. Utilize esta área para manter suas informações pessoais atualizadas e gerenciar seu perfil de maneira eficiente.
        </ExplicacaoInicial>
    </DescriptionContainer>
);

const ValidacaoDescription = () => (
    <DescriptionContainer>
        <ExplicacaoInicial>
            Nesta seção de <span>Validação</span>, você pode completar o processo necessário para verificar e validar sua conta. Capture e envie uma foto de um documento de identificação válido, como RG, CNH ou passaporte, além de uma foto do seu rosto. Uma vez enviados, seus documentos serão processados e verificados em até 7 dias úteis. Acompanhe o status da sua validação diretamente nesta página.
        </ExplicacaoInicial>
    </DescriptionContainer>
);


const AjudaDescription = () => (
    <DescriptionContainer>
        <ExplicacaoInicial>
            A seção de <span>Ajuda</span> oferece suporte direto aos usuários. Aqui, você pode enviar suas dúvidas, questões ou solicitações diretamente para nossa equipe de suporte. Digite sua mensagem no campo abaixo e clique em "ENVIE A MENSAGEM" para iniciar um chat pelo WhatsApp. Nossa equipe estará pronta para ajudar e fornecer assistência personalizada sempre que necessário.
        </ExplicacaoInicial>
    </DescriptionContainer>
);


const DescriptionContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ExplicacaoInicial = styled.div`
    font-size: 16px;
    font-weight: 600;
    text-align: justify;
    color: rgba(0,0,0,0.8);

    span{
        color: blue;
    }
`;

export { HomeDescription, TokenDescription, SaquesDescription, NewsDescription, DownloadsDescription, PerfilDescription, ValidacaoDescription, AjudaDescription };
