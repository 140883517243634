import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import assets from "../assets/assets";
import './styles/Navbar.css';
import { logout } from '../redux/actions';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc, updateDoc } from '@firebase/firestore';
import { firebaseConfig } from '../DATABASE/firebaseConfig';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import styled from 'styled-components';

function NavbarComponent({ colorMode, handleColorMode }) {
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();
  const [userProfile, setUserProfile] = useState(null);
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const toggleNavbar = () => {
    setExpanded(!expanded);
  };

  const handleLogout = () => {
    dispatch(logout());
    window.location.href = '/';
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, 'USERS', localStorage.getItem('cpfCLIENTE'));
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setUserProfile(docSnap.data());
          console.log(userProfile);
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error('Erro ao obter os dados do usuário:', error);
      }
    };

    fetchData();
  }, [db]);

  const navbarStyle = colorMode ? 'navbar-prop-styleBLACKMODE' : 'navbar-prop-style';

  const handleDarkSide = () => {
    window.location.href = 'https://clientes2.modelodesoftwae.com/';
  }

  return (
    <Navbar expand="lg" className="navbar-prop-style" expanded={expanded}>
      <ContainerNavbarMine>
        <Navbar.Brand>
          <img onClick={handleDarkSide} alt="logo" className="icon react-icon" src={colorMode ? assets.reactBlack : assets.react} />
        </Navbar.Brand>
        <img src={assets.menuBlue} alt="Navbar Toggle" className="navbar-toggle-img" onClick={toggleNavbar} />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav-lg">
            <NavDropdown
              title={<img src={userProfile ? userProfile.PROFILEPICTURE : assets.user3} alt="User Icon" className="icon user-icon" />}
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="/perfil">Perfil</NavDropdown.Item>
              {/* <NavDropdown.Item href="action/3.2">Configurações da Conta</NavDropdown.Item> */}
              <NavDropdown.Item href="/ajuda">Ajuda</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={handleLogout} className="text-danger fw-bold">
                Sair
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              title={<img src={assets.config} alt="drop Icon" className="icon drop-icon" />}
              id="basic-nav-dropdown"
              drop="start" // Esta propriedade define a direção de abertura para a esquerda
            >
              <NavDropdown.Item href="validacao">Validação da Conta</NavDropdown.Item>
              <NavDropdown.Item href="/funcionamento">Funcionamento</NavDropdown.Item>
            </NavDropdown>
          </Nav>

          <Nav className="nav-sm">
            <div className="nav-sm-box">
              <Nav.Link className="nav-link-sm" href="/perfil">
                <div className="icon-box-nav">
                  <img src={assets.user2} />
                </div>
                <span>Ver Perfil</span>
              </Nav.Link>

              <Nav.Link className="nav-link-sm" href="/funcionamento">
                <div className="icon-box-nav">
                  <img src={assets.config} />
                </div>
                <span>FUNCIONAMENTO</span>
              </Nav.Link>

              <Nav.Link className="nav-link-sm" href="/validacao">
                <div className="icon-box-nav">
                  <img className="exeptionAsk" src={assets.oftAsk} />
                </div>
                <span>VALIDAÇÃO DA CONTA</span>
              </Nav.Link>

              <Nav.Link className="nav-link-sm" href="/ajuda">
                <div className="icon-box-nav">
                  <img className="exceptionHelp" src={assets.help} />
                </div>
                <span>AJUDA</span>
              </Nav.Link>
            </div>

            <Nav.Link className="nav-link-sm log-out-btn" onClick={handleLogout}>Sair</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </ContainerNavbarMine>

    </Navbar>
  );
}

const ContainerNavbarMine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
`;

export default NavbarComponent;
