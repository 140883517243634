const initialState = {
  isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
  data: JSON.parse(localStorage.getItem('userData')) || {},
  profile: {}, // Novo estado para o perfil do usuário
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem('isLoggedIn', true); // Persiste o estado de login
      return { ...state, isLoggedIn: true };
    case 'LOGOUT':
      localStorage.removeItem('isLoggedIn'); // Remove o estado de login persistido
      localStorage.removeItem('userDataCLIENTE'); // Remove os dados do usuário persistidos
      return { ...state, isLoggedIn: false, data: {}, profile: {} };
    case 'SET_DATA':
      localStorage.setItem('userDataCLIENTE', JSON.stringify(action.payload)); // Persiste os dados do usuário
      return { ...state, data: action.payload };
    case 'SET_PROFILE_DATA': // Novo caso para definir os dados do perfil
      return { ...state, profile: action.payload };
    default:
      return state;
  }
};

export default rootReducer;
