import './styles/Download.css';
import React from 'react';
import assets from '../assets/assets';
import Footer from './Footer';

export default function Download() {
    const downloadFile = () => {

        const fileUrl = 'https://firebasestorage.googleapis.com/v0/b/white-lable-528b0.appspot.com/o/assets%2Fgolden-token-download.pdf?alt=media&token=4667b63d-77ce-4421-8679-847191b6a8d1';

        window.open(fileUrl, '_blank');
    };

    return (
        <div className='Download'>
            <div className='download-area' onClick={downloadFile}>
                <div><img alt='download icon' src={assets.download} /></div>
                <span>DOWNLOAD GOLDEN TOKEN BRASIL</span>
            </div>

        </div>
    );
}
