import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from './redux/actions';
import NavbarComponent from './Components/Navbar';
import NavComponent from './Components/Nav';
import Dashboard from './Components/Dashboard';
import Token from './Components/Token';
import Saques from './Components/Saques';
import Extrato from './Components/Extrato';
import Download from './Components/Download';
import GrapthLikeBinance from './Components/GrapthLikeBinance';
import LoginPage from './Components/LoginPage';
import ProfilePage from './Components/UserPage';
import { initializeApp } from 'firebase/app';
import { getFirestore } from '@firebase/firestore';
import { firebaseConfig } from './DATABASE/firebaseConfig';
import SignUpPage from './Components/SignUpPage';
import News from './Components/News';
import Ajuda from './Components/Ajuda';
import Validacao from './Components/Validacao';
import Funcionamento from './Components/Funcionamento';
import SideBar from './Components/SideBar';
import assets from './assets/assets';

const NAV_LINKS = [
  { name: "HOME", path: "/", icon: assets.dashSvg },
  { name: "TOKEN", path: "/token", icon: assets.tokenSvg },
  { name: "SAQUES", path: "/saques", icon: assets.saqueSvg },
  { name: "EXTRATO", path: "/extrato", icon: assets.saqueSvg },
  { name: "NEWS", path: "/news", icon: assets.newsSvg },
  { name: "DOWNLOADS", path: "/downloads", icon: assets.downloadSvg },
  { name: "FUNCIONAMENTO", path: "/funcionamento", icon: assets.infoSvg },
  { name: "VALIDAÇÃO", path: "/validacao", icon: assets.docSvg },
  { name: "AJUDA", path: "/ajuda", icon: assets.helpSvg },

];

function App() {

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const cpf = localStorage.getItem('cpf')
  const [colorMode, setColorMode] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [userData, setUserData] = useState(null);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    const loggedInStatus = localStorage.getItem('isLoggedIn');
    if (loggedInStatus === 'true') {
      dispatch(login());
    }
  }, [dispatch]);

  const handleColorMode = () => {
    setColorMode(!colorMode);
  };


  return (
    <div className="App">

      <Router>
        {isLoggedIn ? (
          <>
            {windowWidth < 1000 ? (
              <SideBar NAV_LINKS={NAV_LINKS} />
            ) : (
              <>
                <NavbarComponent colorMode={colorMode} handleColorMode={handleColorMode} />
                <NavComponent colorMode={colorMode} />
              </>
            )}

            <Routes>
              <Route path="/home" element={<Dashboard setUserDataApp={setUserData} />} />
              <Route path="/" element={<Dashboard setUserDataApp={setUserData} />} />
              <Route path="/token" element={<Token userData={userData} />} />
              <Route path="/saques" element={<Saques colorMode={colorMode} />} />
              <Route path="/extrato" element={<Extrato />} />
              <Route path="/news" element={<News />} />
              <Route path="/downloads" element={<Download />} />
              <Route path="/grafico" element={<GrapthLikeBinance />} />
              <Route path="/perfil" element={<ProfilePage />} />
              <Route path="/ajuda" element={<Ajuda />} />
              <Route path="/validacao" element={<Validacao />} />
              <Route path="/funcionamento" element={<Funcionamento />} />
            </Routes>
          </>
        ) : (
          <Routes>
            <Route path="/" element={<LoginPage setUserProfile={setUserProfile} />} />
            <Route path="/signup" element={<SignUpPage />} />
          </Routes>
        )}
      </Router>
    </div>
  );
}

export default App;
