import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from 'react-redux';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc, updateDoc } from '@firebase/firestore';
import { firebaseConfig } from '../DATABASE/firebaseConfig';

const whatsicon = 'https://firebasestorage.googleapis.com/v0/b/wldata.appspot.com/o/whatsapp-svgrepo-com.png?alt=media&token=6207949c-a1de-4d5b-ba59-91cda9955415';

export default function Ajuda() {


    const [userProfile, setUserProfile] = useState(null);
    const [texto, setTexto] = useState('');

    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const docRef = doc(db, 'USERS', localStorage.getItem('cpfCLIENTE'));
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setUserProfile(docSnap.data());
                    console.log(userProfile);
                } else {
                    console.error("No such document!");
                }
            } catch (error) {
                console.error('Erro ao obter os dados do usuário:', error);
            }
        };

        fetchData();
    }, [db]);

    const handleWhatsapp = () => {
        if (userProfile) {
            const nome = userProfile.NAME;
            const mensagem = texto;
            const whatsappUrl = `https://wa.me/5517992562727?text=Cliente: ${nome}, mensagem: ${mensagem}`;
            window.open(whatsappUrl, '_blank');
        } else {
            console.error('User profile is not loaded');
        }
    }

    return (
        <AjudaContainer>
            <Formulario>
                <div>
                    <span>Nome: {userProfile && userProfile.NAME}</span>
                </div>
                <Mensagem>
                    <span>Digite sua mensagem</span>
                    <textarea value={texto} onChange={e => setTexto(e.target.value)}></textarea>
                </Mensagem>
            </Formulario>
            <CaixaEnviarMensagem>
                <BotaoWhatsapp onClick={handleWhatsapp}>
                    <img src={whatsicon} />
                    ENVIE A MENSAGEM
                </BotaoWhatsapp>

            </CaixaEnviarMensagem>

        </AjudaContainer>
    )
}

const AjudaContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: aliceblue;
    box-sizing: border-box;
    padding: 50px 30px 200px 40px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 800px){
        flex-direction: column;
        justify-content: center;
        padding: 20px 10px 100px 10px;
    }
`;

const BotaoWhatsapp = styled.button`
    width: 300px;
    height: 40px;
    background-color: rgba(73, 231, 14, 0.8);
    cursor: pointer;
    border: 0;
    box-shadow: 2px 2px 1px rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    font-weight: 600;
    color: rgba(0,0,0,0.7);
    padding: 10px 20px;
    align-items: center;
    img{
        width: 30px;
    }

    transition: .3s;

    &:hover{
        background-color: rgba(73, 200, 14,1);
    }
`;

const Formulario = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column; 
    gap: 20px;

    div{
        span{
            font-size: 22px;
            color: rgba(0,0,0,0.7);
        }
    }
`;

const CaixaEnviarMensagem = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
`;

const Mensagem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    textarea{
        font-size: 18px;
        color: rgba(0,0,0,0.7);
        padding: 20px;
        box-sizing: border-box;
        height: 150px;
    }
`;