import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc } from '@firebase/firestore';
import { firebaseConfig } from '../DATABASE/firebaseConfig';

const formatNumber = (number) => {
    return number.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
};

const parseDateBrazilianFormat = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(year, month - 1, day); // mês - 1 porque o JavaScript conta os meses a partir de 0
};


export default function Extrato() {
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const [userData, setUserData] = useState(null);
    const [contratos, setContratos] = useState([]);
    const [saques, setSaques] = useState([]);
    const [userTotalCoins, setUserTotalCoins] = useState(null);
    const static_value_coin = 158.23;
    const [saldoTotal, setSaldoTotal] = useState(0);
    const [saldoIndicacao, setSaldoIndicacao] = useState(0);

    const fetchData = async () => {
        try {
            const docRef = doc(db, 'USERS', localStorage.getItem('cpfCLIENTE'));
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const userData = docSnap.data();
                setUserData(userData);

                const contratosAtivos = userData.CONTRATOS.filter(contrato => contrato.STATUS === true);
                const saquesAtivos = userData.SAQUES.filter(saque => saque.APROVADO === true);

                // Ordenar por PURCHASEDATE (contratos) e DATA (saques)
                contratosAtivos.sort((a, b) => new Date(b.PURCHASEDATE) - new Date(a.PURCHASEDATE));
                saquesAtivos.sort((a, b) => new Date(b.DATA) - new Date(a.DATA));

                setContratos(contratosAtivos);
                setSaques(saquesAtivos);


                const totalCoins = contratosAtivos.reduce((acc, contrato) => acc + contrato.COINS, 0);
                setUserTotalCoins((totalCoins - parseFloat(docSnap.data().GOTCOINS)));

            } else {
                console.error("No such document!");
            }
        } catch (error) {
            console.error('Erro ao obter os dados do usuário:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (userData && userData.CONTRATOS) {

            let saldo_total = 0;
            let saldo_sacado = 0;

            setSaldoIndicacao(userData.INDICATIONBUDGET)
            saldo_sacado = userData.VALORSACADO;
            userData.CONTRATOS.forEach(contr => {
                if (contr.STATUS) {
                    saldo_total += (contr.TOTALSPENT + (contr.TOTALSPENT * (contr.LUCRO_OBTIDO / 100)))
                }
                setSaldoTotal(saldo_total - saldo_sacado);
            });

        }
    }, [userData]);

    return (
        <ExtratoContainer>

            <ExtratoTitle>
                <span>EXTRATO DA CONTA</span>
            </ExtratoTitle>

            <TableExtratoContainer>
                <Table>
                    <thead>
                        <tr>
                            <th>TIPO</th>
                            <th>DATA</th>
                            <th>DESCRIÇÃO</th>
                            <th>VALOR</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Renderizar contratos */}
                        {contratos.map((contrato, index) => (
                            <tr key={index}>
                                <td>Contrato</td>
                                <td>{contrato.PURCHASEDATE}</td>
                                <td>{contrato.COINS} TOKENS</td>
                                <ValorAzul> + U${formatNumber(contrato.TOTALSPENT)}</ValorAzul>
                            </tr>
                        ))}

                        {/* Renderizar saques */}
                        {saques.map((saque, index) => (
                            <tr key={index}>
                                <td>Saque</td>
                                <td>{saque.DATA}</td>
                                <td>SAQUE</td>
                                <ValorVermelho> - U${formatNumber(saque.VALOR)}</ValorVermelho>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </TableExtratoContainer>

            <CurrentValueBox>
                <p>SALDO ATUAL DA CONTA: <span>U$ {formatNumber(saldoTotal + saldoIndicacao)}</span></p>
            </CurrentValueBox>
        </ExtratoContainer>
    )
}

const ExtratoContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    max-height: max-content;
    background-color: aliceblue;
    box-sizing: border-box;
    padding: 50px 30px 200px 30px;

    display: flex;
    flex-direction: column;
    position: relative;

    @media (max-width: 800px){
        flex-direction: column;
        justify-content: center;
        padding: 60px 10px 100px 10px;
    }
`;

const ExtratoTitle = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 40px;

    span{
        font-size: 22px;
        font-weight: 600;
        color: rgba(0,0,0,0.7);
    }
    
    @media (max-width: 1000px){
        justify-content: center;
        margin-bottom: 20px;
    }
`;

const TableExtratoContainer = styled.div`
    width: 100%;
    overflow-y: scroll;

    @media (max-width: 1000px){
        overflow-x: scroll;
    }
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    text-align: center; /* Centraliza as colunas */
    
    th, td {
        padding: 8px;
        border-bottom: 1px solid #ddd;
    }

    th {
        // background-color: #f2f2f2;
        font-weight: 600;
        font-size: 18px;
    }

    tr:nth-child(even) {
        // background-color: #f2f2f2;
    }

    tbody tr {
        text-align: center; 
        font-weight: 600;
        color: rgba(0,0,0,0.6)
    }
`;

const ValorAzul = styled.td`
    color: blue;
`;

const ValorVermelho = styled.td`
    color: red;
`;

const CurrentValueBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 20px;
    padding: 20px 20px;
    box-sizing: border-box;
    background-color: #111d4a;

    color: white;
    p{
        margin: 0;
        font-weight: 600;
        font-size: 20px;
    }
`;