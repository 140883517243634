import React, { useState, useEffect } from "react";
import styled from "styled-components";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import store from "../redux/store";
import { login } from "../redux/actions";
import { db, getDoc, doc } from '../DATABASE/firebaseConfig';
import { Link } from "react-router-dom";
import Loading from "./Loader";

const logo =
  "https://firebasestorage.googleapis.com/v0/b/white-lable-528b0.appspot.com/o/assets%2FPeroWL.png?alt=media&token=4552ff3d-41f4-4715-85b7-1a2b9f7f7e89";

const firebaseConfig = {
  apiKey: "AIzaSyCnwSOjrqasUNSCp6UrK2moHd1OtLUMj28",
  authDomain: "wldata.firebaseapp.com",
  projectId: "wldata",
  storageBucket: "wldata.appspot.com",
  messagingSenderId: "86184173654",
  appId: "1:86184173654:web:9463c36b71d142b684dbf7",
};

firebase.initializeApp(firebaseConfig);

const LoginPage = ({ setUserProfile }) => {

  const [cpf, setCpf] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [load, setLoad] = useState(false);

  const handleCpfChange = (e) => setCpf(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleLogin = async (cpf, password, setErrorMessage, setUserProfile) => {
    try {
      setLoad(true)
      console.log("Trying to get document with CPF:", cpf);
      const userDoc = await getDoc(doc(db, "USERS", cpf));

      if (!userDoc.exists()) {
        console.error("User data not found for CPF:", cpf);
        setLoad(false)
        setErrorMessage("User data not found");
        return;
      }

      const userData = userDoc.data();
      console.log("User data found:", userData);
      const email = userData.EMAIL;

      await firebase.auth().signInWithEmailAndPassword(email, password);
      setLoad(false)
      console.log("Login successful");
      localStorage.setItem('cpfCLIENTE', cpf);
      store.dispatch(login());
      setUserProfile(userData);
      localStorage.setItem('userDataCLIENTE', JSON.stringify(userData));
    } catch (error) {
      console.error("Error signing in with email and password", error);
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    if (errorMessage) {
      setShowError(true);
      const timeout = setTimeout(() => {
        setShowError(false);
        setErrorMessage("");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [errorMessage]);

  return (
    <Container>
      <Loading load={load} />
      {showError && <Overlay />}
      <LoginBox>
        <Logo src={logo} alt="Logo" />
        <Title>Welcome Back!</Title>
        <LoginForm onSubmit={(e) => {
          e.preventDefault();
          handleLogin(cpf, password, setErrorMessage, setUserProfile);
        }}>
          <Input
            type="text"
            placeholder="CPF"
            value={cpf}
            onChange={handleCpfChange}
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
          />
          <SubmitButton type="submit">Login</SubmitButton>
        </LoginForm>
        {showError && (
          <ErrorPopup>
            <ErrorMessage>USUÁRIO OU SENHA INCORRETOS, TENTE NOVAMENTE</ErrorMessage>
            <ErrorBar />
          </ErrorPopup>
        )}
        <SignUpLink to="/signup">
          Não possui uma conta? <SignUpText>Cadastre-se Já</SignUpText>
        </SignUpLink>
      </LoginBox>
    </Container>
  );
};

const Container = styled.div`
  background-color: #202020;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ show }) => (show ? "block" : "none")};
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
`;

const LoginBox = styled.div`
  position: relative;
  background-color: #333333;
  border-radius: 10px;
  padding: 40px;
  text-align: center;
`;

const Logo = styled.img`
  width: 100px;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 20px;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  background-color: #444444;
  border: none;
  border-radius: 5px;
  padding: 10px;
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 10px;

  &::placeholder {
    color: #bbbbbb;
  }
`;

const SubmitButton = styled.button`
  background-color: #6e6eff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #4b4bff;
  }
`;

const SignUpLink = styled(Link)`
  color: #ffffff;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 20px;
  display: block;
`;

const SignUpText = styled.span`
  text-decoration: none;
`;

const ErrorPopup = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #202020;
  padding: 20px;
  display: flex;
  width: 500px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 9999;

  @media (max-width: 800px){
    width: 300px;
  }
`;

const ErrorMessage = styled.p`
  color: white;
  font-size: 16px;
  margin-bottom: 10px;
`;

const ErrorBar = styled.div`
  width: 100%;
  height: 5px;
  background-color: #2382EF;
  animation: errorBarAnimation 2s linear forwards;
  border-radius: 5px;
  margin-top: 10px;

  @keyframes errorBarAnimation {
    0% {
      width: 100%;
    }
    100% {
      width: 0;
    }
  }
`;

export default LoginPage;
