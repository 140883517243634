export const login = () => ({
  type: 'LOGIN',
});

export const logout = () => ({
  type: 'LOGOUT',
});

export const setData = (data) => ({
  type: 'SET_DATA',
  payload: data,
});

export const setProfileData = (profileData) => ({
  type: 'SET_PROFILE_DATA',
  payload: profileData,
});
